import { MapService } from '../../../service/map';

export const MAPS_FETCH_LOADING_TYPE = Symbol();
export const MAPS_FETCH_FAILURE_TYPE = Symbol();
export const MAPS_FETCH_SUCCESS_TYPE = Symbol();

export function fetchMapsLoading() {
    return {
        type: MAPS_FETCH_LOADING_TYPE,
    };
}

export function fetchMapsFailed(error) {
    return {
        type: MAPS_FETCH_FAILURE_TYPE,
        error,
    };
}

export function fetchMapsSuccess(maps) {
    return {
        type: MAPS_FETCH_SUCCESS_TYPE,
        maps,
    };
}

export function fetchMaps(filter, sortDirection) {
    return async (dispatch) =>  {
        dispatch(fetchMapsLoading());

        try {
            const mapService = new MapService();

            let maps;
            switch(filter) {
                case 'accepted':
                    maps = await mapService.getAcceptedMaps(sortDirection);
                    break;
                case 'pending':
                    maps = await mapService.getPendingMaps(sortDirection);
                    break;
                case 'rejected':
                    maps = await mapService.getRejectedMaps(sortDirection);
                    break;
                default:
                    throw new Error(`Unexpected filter ${filter}`)
            }

            dispatch(fetchMapsSuccess(maps));
        } catch (error) {
            dispatch(fetchMapsFailed(error));
        }
    };
}

export function searchMaps(keywords, sortDirection) {
    return async (dispatch) =>  {
        dispatch(fetchMapsLoading());
        try {
            const maps = await new MapService().searchMaps(keywords, sortDirection);
            dispatch(fetchMapsSuccess(maps));
        } catch (error) {
            dispatch(fetchMapsFailed(error));
        }
    };
}

export const MAPS_ACCEPT_LOADING_TYPE = Symbol();
export const MAPS_ACCEPT_FAILURE_TYPE = Symbol();
export const MAPS_ACCEPT_SUCCESS_TYPE = Symbol();

export function acceptMapsLoading() {
    return {
        type: MAPS_ACCEPT_LOADING_TYPE,
    };
}

export function acceptMapsFailed(error) {
    return {
        type: MAPS_ACCEPT_FAILURE_TYPE,
        error,
    };
}

export function acceptMapsSuccess(maps) {
    return {
        type: MAPS_ACCEPT_SUCCESS_TYPE,
        maps,
    };
}

export function acceptMaps(ids) {
    return async (dispatch) =>  {
        dispatch(acceptMapsLoading());
        try {
            await new MapService().acceptMaps(ids);
            dispatch(acceptMapsSuccess());
        } catch (error) {
            dispatch(acceptMapsFailed(error));
        }
    };
}

export const MAPS_REJECT_LOADING_TYPE = Symbol();
export const MAPS_REJECT_FAILURE_TYPE = Symbol();
export const MAPS_REJECT_SUCCESS_TYPE = Symbol();

export function rejectMapsLoading() {
    return {
        type: MAPS_REJECT_LOADING_TYPE,
    };
}

export function rejectMapsFailed(error) {
    return {
        type: MAPS_REJECT_FAILURE_TYPE,
        error,
    };
}

export function rejectMapsSuccess(maps) {
    return {
        type: MAPS_REJECT_SUCCESS_TYPE,
        maps,
    };
}

export function rejectMaps(ids) {
    return async (dispatch) =>  {
        dispatch(rejectMapsLoading());
        try {
            await new MapService().rejectMaps(ids);
            dispatch(rejectMapsSuccess());
        } catch (error) {
            dispatch(rejectMapsFailed(error));
        }
    };
}
