import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

function sortFilter(props) {
    return (
        <FormControl>
            <InputLabel id='sort-label'>Sort</InputLabel>
            <Select
                labelId='sort-label'
                value={props.value}
                onChange={(event) => {
                    props.onChange(event.target.value);
                }}
            >
                <MenuItem value={'newest'}>Newest</MenuItem>
                <MenuItem value={'oldest'}>Oldest</MenuItem>
            </Select>
        </FormControl>
    )
}

export default sortFilter;

