import { Component } from 'react';

import {
    Button,
    FormControl,
    TextField,
    Typography,
} from '@material-ui/core';

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            email: '',
            password: '',
        };

        this.updateEmail = this.updateEmail.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
    }

    updateEmail(event) {
        this.setState({
            email: event.target.value,
        });
    }

    updatePassword(event) {
        this.setState({
            password: event.target.value,
        });
    }

    render() {
        return (
            <FormControl>
                <TextField
                    id='email-input'
                    label='Email address'
                    type='email'
                    onChange={this.updateEmail}
                />
                <TextField
                    id='password-input'
                    label='Password'
                    type='password'
                    onChange={this.updatePassword}
                    style={{ marginTop: 10 }}
                />
                <Button
                    onClick={() => {
                        this.props.onSignIn({
                            email: this.state.email,
                            password: this.state.password
                        });
                    }}
                    style={{ marginTop: 10 }}
                >
                    <Typography variant='body'>
                        Sign In
                    </Typography>
                </Button>
            </FormControl>
        );
    }
}

export default SignIn;
