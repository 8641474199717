import {
    MAPS_FETCH_LOADING_TYPE,
    MAPS_FETCH_FAILURE_TYPE,
    MAPS_FETCH_SUCCESS_TYPE,
    MAPS_ACCEPT_LOADING_TYPE,
    MAPS_ACCEPT_FAILURE_TYPE,
    MAPS_ACCEPT_SUCCESS_TYPE,
    MAPS_REJECT_LOADING_TYPE,
    MAPS_REJECT_FAILURE_TYPE,
    MAPS_REJECT_SUCCESS_TYPE,
} from '../../action/map';

const initialState = {
    isLoading: false,
    error: null,
    maps: null,
    hasUpdatedStatus: false,
};

export function mapReducer(state = initialState, action) {
    switch (action.type) {
        case MAPS_FETCH_LOADING_TYPE:
            return Object.assign({}, state, {
                isLoading: true,
                error: null,
                maps: null,
                hasUpdatedStatus: false,
            });
        case MAPS_FETCH_FAILURE_TYPE:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                maps: null,
            });
        case MAPS_FETCH_SUCCESS_TYPE:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                maps: action.maps,
            });
        case MAPS_ACCEPT_LOADING_TYPE:
        case MAPS_REJECT_LOADING_TYPE:
            return Object.assign({}, state, {
                isLoading: true,
                error: null,
                hasUpdatedStatus: false,
            });
        case MAPS_ACCEPT_FAILURE_TYPE:
        case MAPS_REJECT_FAILURE_TYPE:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                hasUpdatedStatus: false,
            });
        case MAPS_REJECT_SUCCESS_TYPE:
        case MAPS_ACCEPT_SUCCESS_TYPE:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                hasUpdatedStatus: true,
            });
        default:
            return state;
    }
}
