import { Component } from 'react';
import {
    Switch,
    Route,
} from 'react-router-dom';

import {
    AppBar,
    Container,
    Button,
    Toolbar,
    Typography,
} from '@material-ui/core';

import { connect } from 'react-redux';
import { Home, SignIn } from './page';
import { signOut } from './store/action/user';

class App extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        return (
            <div>
                <AppBar color='transparent' elevation={0} position='static'>
                    <Toolbar>
                        <Typography
                            onClick={() => { this.props.history.push('/') }}
                            style={{cursor: 'pointer'}}
                            variant='h6'
                        >
                            Map Review
                        </Typography>
                        <div style={{ flexGrow: 1 }} />
                        {
                            this.renderMenuItems()
                        }
                    </Toolbar>
                </AppBar>
                <Container
                    maxWidth={ 'md' }
                    style={{
                        marginHorizontal: 'auto',
                        marginVeritcal: 20,
                    }}
                >
                    <Switch>
                        <Route path='/' exact component={Home} />
                        <Route path='/signIn' component={SignIn} />
                    </Switch>
                </Container>
            </div>
        );
    }

    renderMenuItems() {
        if (!this.props.user && this.props.history.location.pathname !== '/signIn') {
            return this.renderSignIn();
        } else {
            return this.renderSignOut();
        }
    }

    renderSignIn() {
        return (
            <Button
                color='inherit'
                onClick={() => {
                    this.props.history.push('/signIn');
                }}
            >
                Sign In
            </Button>
        );
    }

    renderSignOut() {
        return (
            <Button
                color='inherit'
                onClick={() => {
                    this.props.signOut();
                }}
            >
                Sign Out
            </Button>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        signOut: () => {
            dispatch(signOut());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
