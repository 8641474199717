import { Component } from 'react';
import { connect } from 'react-redux';

import { Button, Grid, Typography } from '@material-ui/core';
import { CheckCircle, Cancel } from '@material-ui/icons';

import MapCard from '../../component/map';
import Loading from '../../component/loading';

import { fetchMaps, searchMaps, rejectMaps, acceptMaps } from '../../store/action/map';
import { fetchUser } from '../../store/action/user';
import StatusFilter from '../../component/statusFilter';
import SortFilter from '../../component/sortFilter';

class Home extends Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            sortDirection: 'oldest',
            filter: 'pending',
            selected: new Set(),
        }
    }

    componentDidMount() {
        if (!this.props.user) {
            this.props.fetchUser();
        } else if (!this.props.maps) {
            this.props.fetchMaps(this.state.filter, this.state.sortDirection === 'oldest' ? 'asc' : 'desc');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            !this.props.error &&
            !this.props.isLoading && (
                !this.props.maps ||
                this.state.filter !== prevState.filter ||
                this.state.sortDirection !== prevState.sortDirection ||
                (
                    this.props.hasUpdatedStatus && !prevProps.hasUpdatedStatus
                )
            )
        ) {
            if (this.state.selected.size > 0) {
                this.setState({ selected: new Set() });
            }
            this.props.fetchMaps(this.state.filter, this.state.sortDirection === 'oldest' ? 'asc' : 'desc');
        }
    }

    render() {
        if (!this.props.user) {
            return this.renderSignedOut();
        } else {
            return (
                <div>
                    {
                        this.renderMenuBar()
                    }

                    {
                        this.renderContent()
                    }
                </div>
            );
        }
    }

    renderContent() {
        if (this.props.error) {
            return this.renderError();
        }  else if (this.props.isLoading || this.props.maps === null) {
            return this.renderLoading();
        } else if (this.props.maps.length === 0) {
            return this.renderNoMaps();
        } else {
            return this.renderMapGrid();
        }
    }

    renderLoading() {
        return (
            <Loading />
        );
    }

    renderSignedOut() {
        return (
            <Typography variant='body1'>
                Please sign in.
            </Typography>
        );
    }

    renderError() {
        return (
            <Typography variant='body1'>
                Something went wrong. Please try again.
            </Typography>
        );
    }

    renderNoMaps() {
        return (
            <Typography variant='body1'>
                There are no maps.
            </Typography>
        );
    }

    renderMenuBar() {
        return (
            <Grid
                container
                spacing={3}
            >
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={ <CheckCircle /> }
                        onClick={() => {
                            this.props.acceptMaps(this.state.selected);
                        }}
                    >
                        Accept
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={ <Cancel /> }
                        onClick={() => {
                            this.props.rejectMaps(this.state.selected);
                        }}
                    >
                        Reject
                    </Button>
                </Grid>
                <Grid item xs>
                </Grid>
                <Grid item>
                    <StatusFilter
                        value={this.state.filter}
                        onChange={(filter) => {
                            this.setState({ filter });
                        }} />
                </Grid>
                <Grid item>
                    <SortFilter
                        value={this.state.sortDirection}
                        onChange={(sortDirection) => {
                            this.setState({ sortDirection });
                        }} />
                </Grid>
            </Grid>
        );
    }

    renderMapGrid() {
        return (
            <Grid
                container
                spacing={2}
                justify='center'
            >
                {
                    (this.props.maps || []).map(this.renderMap.bind(this))
                }
            </Grid>
        );
    }

    renderMap(map) {
        return (
            <Grid
                item
                xs={12}
                sm={6}
            >
                <MapCard
                    key={map.id}
                    map={map}
                    onClick={() => {
                        window.open(map.link)
                    }}
                    selected={ this.state.selected.has(map.id) }
                    onSelectedChange={(selected) => {
                        if (selected) {
                            const newSelected = new Set(this.state.selected).add(map.id);
                            this.setState( { selected: newSelected })
                        } else {
                            const newSelected = new Set(this.state.selected);
                            newSelected.delete(map.id);
                            this.setState( { selected: newSelected })
                        }
                    }} />
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user.user,
        maps: state.map.maps,
        isLoading: state.user.isLoading || state.map.isLoading,
        hasUpdatedStatus: state.map.hasUpdatedStatus,
        error: state.map.error,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchMaps: (filter, sortDirection) => {
            dispatch(fetchMaps(filter, sortDirection));
        },
        searchMaps: (keywords, sortDirection) => {
            // TODO: use search
            dispatch(searchMaps(keywords, sortDirection));
        },
        fetchUser: () => {
            dispatch(fetchUser());
        },
        rejectMaps: (ids) => {
            dispatch(rejectMaps(ids));
        },
        acceptMaps: (ids) => {
            dispatch(acceptMaps(ids));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
