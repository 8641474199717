import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

function statusFilter(props) {
    return (
        <FormControl>
            <InputLabel id='filter-label'>Status</InputLabel>
            <Select
                labelId='filter-label'
                value={props.value}
                onChange={(event) => {
                    props.onChange(event.target.value);
                }}
            >
                <MenuItem value={'accepted'}>Accepted</MenuItem>
                <MenuItem value={'pending'}>Pending</MenuItem>
                <MenuItem value={'rejected'}>Rejected</MenuItem>
            </Select>
        </FormControl>
    )
}

export default statusFilter;
