import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import firebase from 'firebase/app';
import {BrowserRouter, Route} from 'react-router-dom';

import './index.css';
import App from './App';
import { store } from './store';
import { fetchUser } from './store/action/user';

function initializeFirebase() {
    firebase.initializeApp({
        apiKey: "AIzaSyBM_QkY7R8EmHhPFf3IqG0UXLRB3ic5Kg4",
        authDomain: "maps-for-minecraft-pe-5ea66.firebaseapp.com",
        databaseURL: "https://maps-for-minecraft-pe-5ea66.firebaseio.com",
        projectId: "maps-for-minecraft-pe-5ea66",
        storageBucket: "maps-for-minecraft-pe-5ea66.appspot.com",
        messagingSenderId: "627990968413",
        appId: "1:627990968413:web:f8e52417d1e4e27e2675b4",
        measurementId: "G-F324HW5YB2"
    });
    firebase.auth().onAuthStateChanged(() => {
        store.dispatch(fetchUser());
    });
}
initializeFirebase();

function AppWithProvider(props) {
    return (
        <Provider store={store}>
            <App {...props} />
        </Provider>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Route path="/" component={ AppWithProvider }/>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
