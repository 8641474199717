import { MapRepository } from '../../repository/map';

export class MapService {
    constructor(mapRepository = new MapRepository()) {
        this.mapRepository = mapRepository;
    }

    getPendingMaps(sortDirection) {
        return this.mapRepository.getPendingMaps(sortDirection);
    }

    getAcceptedMaps(sortDirection) {
        return this.mapRepository.getAcceptedMaps(sortDirection);
    }

    getRejectedMaps(sortDirection) {
        return this.mapRepository.getRejectedMaps(sortDirection);
    }

    searchMaps(keywords, sortDirection) {
        return this.mapRepository.searchMaps(keywords, sortDirection);
    }

    acceptMaps(ids) {
        return this.mapRepository.acceptMaps(ids);
    }

    rejectMaps(ids) {
        return this.mapRepository.rejectMaps(ids)
    }
}
