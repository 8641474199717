import { UserService } from '../../../service/user';
import { NotSignedInException } from '../../../exception';

export const USER_FETCH_LOADING_TYPE = Symbol();
export const USER_FETCH_FAILURE_TYPE = Symbol();
export const USER_FETCH_SUCCESS_TYPE = Symbol();

export function fetchUserLoading() {
    return {
        type: USER_FETCH_LOADING_TYPE,
    };
}

export function fetchUserFailed(error) {
    return {
        type: USER_FETCH_FAILURE_TYPE,
        error,
    };
}

export function fetchUserSuccess(user) {
    return {
        type: USER_FETCH_SUCCESS_TYPE,
        user,
    };
}

export function fetchUser() {
    return (dispatch) =>  {
        dispatch(fetchUserLoading());
        const user = new UserService().getUser();
        if (user) {
            dispatch(fetchUserSuccess(user));
        } else {
            dispatch(fetchUserFailed(new NotSignedInException()));
        }
    };
}

export const USER_SIGN_IN_LOADING_TYPE = Symbol();
export const USER_SIGN_IN_FAILURE_TYPE = Symbol();
export const USER_SIGN_IN_SUCCESS_TYPE = Symbol();

export function signInUserLoading() {
    return {
        type: USER_SIGN_IN_LOADING_TYPE,
    };
}

export function signInUserFailed(error) {
    return {
        type: USER_SIGN_IN_FAILURE_TYPE,
        error,
    };
}

export function signInUserSuccess(user) {
    return {
        type: USER_SIGN_IN_SUCCESS_TYPE,
        user,
    };
}

export function signInUser(email, password) {
    return (dispatch) =>  {
        dispatch(signInUserLoading());
        new UserService().signInUser(email, password).then((user) => {
            dispatch(signInUserSuccess(user));
        }).catch(error => {
            dispatch(signInUserFailed(error));
        });
    };
}

export const SIGN_OUT_LOADING_TYPE = Symbol();
export const SIGN_OUT_FAILURE_TYPE = Symbol();
export const SIGN_OUT_SUCCESS_TYPE = Symbol();

export function signOutLoading() {
    return {
        type: SIGN_OUT_LOADING_TYPE,
    };
}

export function signOutFailed(error) {
    return {
        type: SIGN_OUT_FAILURE_TYPE,
        error,
    };
}

export function signOutSuccess() {
    return {
        type: SIGN_OUT_SUCCESS_TYPE,
    };
}

export function signOut() {
    return (dispatch) =>  {
        dispatch(signOutLoading());
        new UserService().signOut().then(() => {
            dispatch(signOutSuccess());
        }).catch(error => {
            dispatch(signOutFailed(error));
        });
    };
}

export const ERROR_RESET_TYPE = Symbol();

export function clearError() {
    return {
        type: ERROR_RESET_TYPE,
    };
}
