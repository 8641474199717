import { UserRepository } from '../../repository/user';

export class UserService {
    constructor(userRepository = new UserRepository()) {
        this.userRepository = userRepository;
    }

    getUser() {
        return this.userRepository.getUser();
    }

    signInUser(email, password) {
        return this.userRepository.loginUser(email, password);
    }

    signOut() {
        return this.userRepository.signOut();
    }
}
