import { Component } from 'react';

import { connect } from 'react-redux';

import {
    Typography
} from '@material-ui/core';

import { SignIn } from '../../../component/user';
import Loading from '../../../component/loading';

import { signInUser, fetchUser, clearError } from '../../../store/action/user';

import { NotSignedInException } from '../../../exception';

class SignInPage extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user) {
            this.props.history.push('/');
        }
    }

    componentDidMount() {
        if (this.props.user) {
            this.props.history.push('/');
        } else {
            this.props.fetchUser();
        }
    }

    componentWillUnmount() {
        this.props.clearError();
    }

    render() {
        if (this.props.isLoading) {
            return (
                <Loading />
            );
        } else  if (this.props.error && !(this.props.error instanceof NotSignedInException)) {
            return (
                <Typography variant='body'>
                    Something went wrong. Please try again.
                </Typography>
            );
        } else {
            return (
                <SignIn
                    onSignIn={this.props.signInUser} />
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.user.user,
        isLoading: state.user.isLoading,
        error: state.user.error,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        signInUser: (credentials) => {
            dispatch(signInUser(credentials.email, credentials.password));
        },
        fetchUser: () => {
            dispatch(fetchUser());
        },
        clearError: () => {
            dispatch(clearError());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
