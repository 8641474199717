import { CircularProgress } from '@material-ui/core';

function loading() {
    return (
        <div
            style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CircularProgress />
        </div>

    );
}


export default loading;
