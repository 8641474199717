import { Card, CardContent, Checkbox, FormControlLabel, Typography, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const GreyCheckbox = withStyles({
    root: {
        '&$checked': {
            color: grey[900],
        },
    },
})((props) => <Checkbox color="default" {...props} />);

function mapCard(props) {
    return (
        <Card
            style={props.style}
        >
            <div
                style={{ position: 'relative' }}
            >
                <img
                    height={236}
                    width={480}
                    src={props.map.image}
                    style={{
                        backgroundImage: 'url("/map_placeholder.png")'
                    }}
                    loading={'lazy'}
                    referrerPolicy={'no-referrer'} />
                {
                    props.selected !== undefined && (
                        <FormControlLabel
                            control={
                                <GreyCheckbox
                                    checked={props.selected}
                                    onChange={(event) => {
                                        props.onSelectedChange(event.target.checked)
                                    }}
                                />
                            }
                            label=''
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 10,
                                padding: 2,
                                backgroundColor: '#FFFFFFCC',
                                borderRadius: 5
                            }}
                        />
                    )
                }
            </div>
            <CardContent
                style={{ height: 28 }}
            >
                <Typography
                    gutterBottom
                    variant='h6'
                    component='h2'
                    onClick={props.onClick}
                    style={ props.onClick ? { cursor: 'pointer' } : {} }
                >
                    {props.map.title}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default mapCard;
