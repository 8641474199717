import {
    USER_FETCH_LOADING_TYPE,
    USER_FETCH_FAILURE_TYPE,
    USER_FETCH_SUCCESS_TYPE,
    USER_SIGN_IN_LOADING_TYPE,
    USER_SIGN_IN_FAILURE_TYPE,
    USER_SIGN_IN_SUCCESS_TYPE,
    SIGN_OUT_LOADING_TYPE,
    SIGN_OUT_FAILURE_TYPE,
    SIGN_OUT_SUCCESS_TYPE,
    ERROR_RESET_TYPE,
} from '../../action/user';

const initialState = {
    isLoading: false,
    isSigningOut: false,
    error: null,
    user: null,
    signedOut: false,
};

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case SIGN_OUT_LOADING_TYPE:
            return Object.assign({}, state, {
                isSigningOut: true,
                error: null,
                signedOut: false,
            });
        case USER_FETCH_LOADING_TYPE:
        case USER_SIGN_IN_LOADING_TYPE:
            return Object.assign({}, state, {
                isLoading: true,
                error: null,
            });
        case SIGN_OUT_FAILURE_TYPE:
            return Object.assign({}, state, {
                isSigningOut: false,
                error: action.error,
                signedOut: false,
            });
        case USER_FETCH_FAILURE_TYPE:
        case USER_SIGN_IN_FAILURE_TYPE:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                user: null,
            });
        case SIGN_OUT_SUCCESS_TYPE:
            return Object.assign({}, state, {
                isSigningOut: false,
                error: null,
                signedOut: true,
                user: null,
            });
        case USER_FETCH_SUCCESS_TYPE:
        case USER_SIGN_IN_SUCCESS_TYPE:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                user: action.user,
                signedOut: false
            });
        case ERROR_RESET_TYPE:
            return Object.assign({}, state, {
                error: null,
            });
        default:
            return state;
    }
}
