import firebase from 'firebase';

export class UserRepository {
    constructor(
        firebaseAuth = firebase.auth()
    ) {
        this.firebaseAuth = firebaseAuth;
    }

    getUser() {
        return this.firebaseAuth.currentUser;
    }

    async loginUser(email, password) {
        const credentials = await this.firebaseAuth.signInWithEmailAndPassword(email, password);
        return credentials.user;
    }

    signOut() {
        return this.firebaseAuth.signOut();
    }
}
