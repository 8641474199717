import firebase from 'firebase';

export class MapRepository {
    constructor(
        firestore = firebase.firestore()
    ) {
        this.firestore = firestore;
    }

    async getPendingMaps(sortDirection='asc') {
        const snapshot = await this.firestore.collection('maps')
            .where('status', '==', 'pending')
            .orderBy('created', sortDirection)
            .limit(50)
            .get();
        return this.snapshotToArray(snapshot);
    }

    snapshotToArray(snapshot) {
        return snapshot.docs.map((doc) => {
            return {
                id: doc.id,
                ...doc.data()
            }
        })
    }

    async getAcceptedMaps(sortDirection='asc') {
        const snapshot = await this.firestore.collection('maps')
            .where('status', '==', 'accepted')
            .orderBy('created', sortDirection)
            .limit(50)
            .get();
        return this.snapshotToArray(snapshot);
    }

    async getRejectedMaps(sortDirection='asc') {
        const snapshot = await this.firestore.collection('maps')
            .where('status', '==', 'rejected')
            .orderBy('created', sortDirection)
            .limit(50)
            .get();
        return this.snapshotToArray(snapshot);
    }

    async searchMaps(keywords, sortDirection='asc') {
        const snapshot = await this.firestore.collection('maps')
            .where('title', '==', keywords)
            .orderBy('created', sortDirection)
            .limit(50)
            .get();
        return this.snapshotToArray(snapshot);
    }

    async acceptMaps(ids) {
        const batch = this.firestore.batch();
        const mapsCollection = this.firestore.collection('maps');
        ids.forEach((id) => {
            batch.update(mapsCollection.doc(id), { status: 'accepted' });
        });
        await batch.commit();
    }

    async rejectMaps(ids) {
        const batch = this.firestore.batch();
        const mapsCollection = this.firestore.collection('maps');
        ids.forEach((id) => {
            batch.update(mapsCollection.doc(id), { status: 'rejected' });
        });
        await batch.commit();
    }
}
